// General colors

$color-white: #FFF;
$color-black: #000;

$color-1: $color-white;
$color-2: #404040; // Color text

// Color schemes 
$base-00: #2b2b2b;
$base-01: #404040;
$base-02: #3a4055;
$base-03: #5a647e;
$base-04: #d4cfc9;
$base-05: #e6e1dc;
$base-06: #f4f1ed;
$base-07: #f9f7f3; // Not for theme selection
$base-08: #da4939; // color ssilok
$base-09: #cc7833;
$base-0A: #ffc66d;
$base-0B: #a5c261;
$base-0C: #519f50;
$base-0D: #6d9cbe;
$base-0E: #b6b3eb;
$base-0F: #bc9458;
$base-ssilki: #0070c9;
$base-excerpt: #000;



// Fonts 
$primary-font: Roboto, sans-serif;

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    src: local("Roboto"), local("Roboto-Regular"), url(/assets/fonts/roboto-v18-cyrillic_latin-regular.woff2) format("woff2"), url(/assets/fonts/roboto-v18-cyrillic_latin-regular.woff) format("woff")
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    src: local("Roboto Italic"), local("Roboto-Italic"), url(/assets/fonts/roboto-v18-cyrillic_latin-italic.woff2) format("woff2"), url(/assets/fonts/roboto-v18-cyrillic_latin-italic.woff) format("woff")
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    src: local("Roboto Medium"), local("Roboto-Medium"), url(/assets/fonts/roboto-v18-cyrillic_latin-500.woff2) format("woff2"), url(/assets/fonts/roboto-v18-cyrillic_latin-500.woff) format("woff")
}

@font-face {
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    src: local("Roboto Medium Italic"), local("Roboto-MediumItalic"), url(/assets/fonts/roboto-v18-cyrillic_latin-500italic.woff2) format("woff2"), url(/assets/fonts/roboto-v18-cyrillic_latin-500italic.woff) format("woff")
}