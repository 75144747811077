// Import reset 
@import "partials/reset";

// Import colors, fonts etc...
@import "partials/variables";

// Import custom mixins
@import "partials/mixins";

// Import layout mixin
@import "partials/layout";

// Import syntax 
@import "partials/syntax";


/* -------------------------------- 

 Primary style

-------------------------------- */

html * {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $primary-font; // variables inside partials > _variables.scss
    line-height: 1.5
}

*, *:after, *:before {
  @include box-sizing(border-box);
}

body {
  font: {
    size: 100%;
    family: $primary-font; // variables inside partials > _variables.scss
  }
}

.wc-container {
    width: 90%;
    max-width: $M; // see breakpoints in _sass/partials/_layout.scss
    margin: 0 auto;

    &::after { /* Clearfix */
        content: '';
        display: table;
        clear: both;
    }
}

.wc-img-replace { /* replace text with a background-image */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background-repeat: no-repeat;
  background-position: center center;
}

.post-footer {
  
  height: 40px;
  display: table;
  width: 100%;
  position: relative;

  @include MQ(M) {    
    height: 80px;
  }
  
.column-full {
    text-align: center;
    width: 100%;
  }
}



/* --------------------------------

 Small Typography

--------------------------------- */

h2, h3, h4, h5, h6 { 
  line-height: 1.6;
  font-weight: 500;
}

h1 {
  line-height: 1.3;
  // mobile first
  @include font-size(20px);
  @include MQ(M) {
    @include font-size(33px);
  }
}

h2 {
  // mobile first
  @include font-size(16px);
  @include MQ(M) {
    @include font-size(24px);
  }
}

h3 {
  @include font-size(15px);
  @include MQ(M) {
    @include font-size(30px);
  }
}

h4 {
  @include font-size(16px);
  @include MQ(M) {
    @include font-size(24px);
  }
}

h5 {
  @include font-size(14px);
  @include MQ(M) {
    @include font-size(22px);
  }
}

h6 {
  @include font-size(14px);
  @include MQ(M) {
    @include font-size(19px);
  }
}

p {

  }

a {
  background: transparent;
}

a:active, a:hover, a:focus {
  outline: 0;
}

img {
  border: 0;
  display: inline;
  max-width: 100%;
  height: auto;
}


hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
  height: 0;
}

/* bold text */
strong, b { font-weight: 600; }

/* italic */
em, i { font-style: italic; }
/* --------------------------------

 Theme base

--------------------------------- */

// default 

a.default-anchor {
  
  text-decoration: none;
  border-bottom: 1px solid transparent;
  &:hover, &:active, &:focus {
    @include transition(border-bottom-color .2s);
  }

}

div.default-page-content {
  h1, h2, h3, h4, h5, h6 { color: $base-00; }
}

body {
  color: $color-2;
}

a {
  @extend .default-anchor;
}

header.main-header, footer.main-footer {
 background: url(/img/bg.jpg);
  p,address {
    @include font-size(12px);
    @include MQ(M) {
      @include font-size(14px);        
    }
  }
}

header.main-header {
  color: $base-00;

  h1, h2 { text-align: center; color: inherit;}
  a { 
    color: inherit;
    &:hover, &:active, &:focus {
      border-bottom-color: transparent;
    }
  }

  ul {
    width: 100%;
    text-align: center;
    padding: 2px;
  }
  ul li {       
    display: inline-block;
    position:relative;
  }

  ul li span {
    float: left;
    display: inline-block;
    width: 15px;
  }

  ul li a { 
    display:inline-block;
    color: inherit;
    border-bottom: 2px transparent solid;
    padding-bottom:5px;
    @include transition(all, .2s);
    @include MQ(M) {
      @include font-size(18px);
    }
    &:hover, &:focus, &:active {
      border-bottom-color: inherit;
    }
  }
  

}


div.page-content {
  @extend .default-page-content;
  min-height: initial;

  @include MQ(M) {
    min-height: 400px;
  }
}    

div.page-content {
  ul {   
  te {
      @include font-size(14px);
      color: $color-2; 
      line-height: 1.6;
      background-repeat: no-repeat;
      background-position: 0 6px;
      padding: 0 0 5px 15px;
      margin-left: 0;
    } 
       
    padding: 0 0 1em 0;
    li {
      @include font-size(14px);
      color: $color-2; 
      line-height: 1.6;
      background: url(./images/bullet.png);
      background-repeat: no-repeat;
      background-position: 0 6px;
      padding: 0 0 5px 15px;
      margin-left: 0;
    }
    @include MQ(M){        
      li {         
        @include font-size(15px);  
        line-height: 1.5;
        background-position: 0 7px; 
      }
      te {         
        @include font-size(15px);  
        line-height: 1.5;
        background-position: 0 7px; 
      }
    }
  }
  ol { 
    @include font-size(12px);
    // padding-top: 1em;
    padding-bottom: 1em;
    li {
      color: $color-2; 
      line-height: 1.6;
      padding: 0 0 5px 0px;
      margin-left: 2em;
      position:relative;
    }
    @include MQ(M){
      @include font-size(15px);
      li { 
        line-height: 1.5;
        background-position: 0 7px; 
      }
    }
  }

  dl {
    padding-bottom: 1em;
    dt, dd {
      @include font-size(12px);  
      line-height: 1.5;        
    }
    
    dt {
      font-weight: bold;
      padding-bottom: 5px;
      padding-top: 20px;
    }

    dt:first-child {
      padding-top: 0px;
    }

    dd {
      padding-left: 10px;
    }

    @include MQ(M) {        
      dt, dd {
        @include font-size(15px);
        line-height: 1.6;        
      }
    }
  }
  
  img { 
    padding: 1em 0;
  }

  p {
    margin-bottom: 1em;      
  }

  h1, h2, h3, h4, h5, h6 { 
    padding-bottom: 2px;
  }
}


// Theme base 01
body.theme-base-01 {
  
  a {
    @extend .default-anchor;   
  }

  footer.main-footer {
    background-color: $base-01;
    color: $base-07;
  }

  header.main-header {
    color: $base-01;
  }

  div.page-content {
    h1, h2, h3, h4, h5, h6 { color: $base-01; }
  }


}

/* --------------------------------

 Main Header

--------------------------------- */

header.main-header { 
  padding: 1em 0;
  @include MQ(M) { padding: 2em 0; }
}
footer.main-footer {
  padding: 1em 0;
  @include MQ(M) { padding: 2em 0; }
}
.page-content {
  padding: 1em 0;
  @include MQ(M) { padding: 2em 0; }
}


/* ---------------------------------

  Blockquote

---------------------------------- */
blockquote {
  padding: .5em 1em;
  margin: .8em 0;
  font-weight: 500;
  color: #404040;
  border-left: .25em solid #e5e5e5;
}
blockquote p:last-child {
  margin-bottom: 0;
}

@include MQ(M) {
  blockquote {
    padding-right: 5em;
    padding-left: 1.25em;
  }
}


/* --------------------------------

Code snippets

--------------------------------- */
code, pre {
  font-family: $primary-font; // variables inside partials > _variables.scss
}
code {  
  padding: .25em .5em;
  font-size: 85%;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1em;
  padding: 1em;
  @include font-size(12px);
  line-height: 1.4;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  background-color: $color-white;
  border: 1px solid $base-05;
  code {
    padding: 0;
    font-size: 100%;
    color: inherit;
    background-color: transparent;
  }

  @include MQ(M) {
    @include font-size(14px);
  }
}

.highlight {
  margin-bottom: 1em;
  margin-top: 1em;
  pre {
    margin-bottom: 0;
  }
}


/* -----------------------------------

  Table

----------------------------------- */
table {
  width: 100%;
  border-collapse: collapse;
}
td,
th {

}

.post-meta {
  color: #666;
  clear: both;
  font-size: 0.95rem;
  line-height: 18px;
  border-bottom: 1px solid #d5d5d5;
}
.post-meta span {
  margin-right: 4px;
  vertical-align: middle;
}
.post-meta a {
  font-weight: 500;
}


/**
 * Tags and Categories Style
 */

.tags-box{
 obvodka
{
display: inline-block;
    position:relative;
    color: white;
	padding-bottom: 1px;
	padding-top: 1px;
	padding-left:3px;
	padding-right:1px;
	background-color:#139BE1;
	background-clip: padding-box;
	border: 1px solid;
	border-radius: 1px;
	cursor: pointer;
	-moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.08), 0 1px 2px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.08), 0 1px 2px rgba(0, 0, 0, 0.25);
	box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.08), 0 1px 2px rgba(0, 0, 0, 0.25); 
	background: #1097e6;
	border-color: #0d78b6 #0d78b6 #0b689e;
		background-image: -webkit-linear-gradient(top, #25a5f0, #1097e6 66%, #0f8ad3);
	background-image: -moz-linear-gradient(top, #25a5f0, #1097e6 66%, #0f8ad3);
	background-image: -o-linear-gradient(top, #25a5f0, #1097e6 66%, #0f8ad3);
	background-image: linear-gradient(to bottom, #25a5f0, #1097e6 66%, #0f8ad3);
}

 tags
 {
display: inline-block;
    position:relative;
    color: white;
	padding-bottom: 1px;
	padding-top: 1px;
	padding-left:3px;
	padding-right:1px;
	background-color:#F99D1B;
	background-clip: padding-box;
	border: 1px solid;
	border-radius: 2px;
	cursor: pointer;
	-moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.08), 0 1px 2px rgba(0, 0, 0, 0.25);
	-webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.08), 0 1px 2px rgba(0, 0, 0, 0.25);
	box-shadow: inset 0 1px rgba(255, 255, 255, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.08), 0 1px 2px rgba(0, 0, 0, 0.25); 
	background: #f4902a;
	border-color: #df770c #df770c #c76a0a;
	background-image: -webkit-linear-gradient(top, #f69f47, #f4902a 66%, #f38617);
	background-image: -moz-linear-gradient(top, #f69f47, #f4902a 66%, #f38617);
	background-image: -o-linear-gradient(top, #f69f47, #f4902a 66%, #f38617);
	background-image: linear-gradient(to bottom, #f69f47, #f4902a 66%, #f38617);
}

    .size{
    font-size: 0.7em; font-weight: bold; vertical-align: super;
    text-shadow:0 1px 0 white;
	color:#9e8371;
  }
}





/* -------------------------------- 

 Размеры видео

-------------------------------- */


.adapt_youtube {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.adapt_youtube iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

.adapt_size {
    position: relative;
    padding-bottom: 30px;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.adapt_size iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* -------------------------------- 

   Здарова! Это я Vudi, ты чо тут забыл?)

-------------------------------- */
